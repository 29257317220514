<template>
  <div>
    <!-- <div class="m-0"> -->
    <!-- <template v-if="$options.components['advertisement-banner']">
        <advertisement-banner />
      </template> -->
    <!-- </div> -->
    <div class="container">
      <b-row>
        <b-col
          md="3"
          class="mx-3 my-4 text-grey"
          v-if="filterProduct.length != 0"
        >
          <template v-if="$options.components['search-side-bar']">
            <search-side-bar @allFilters="allfilter" :themeColor="themeColor" />
          </template>
        </b-col>
        <b-col md="8" class="mt-3">
          <div class="container">
            <div v-if="filterProduct.length != 0" class="row text-grey">
              <b-col md="9">
                <h4 class="font-weight-bold mb-0">
                  {{
                    $route.params.id[0].toUpperCase() +
                    $route.params.id.substring(1).toLowerCase()
                  }}
                </h4>
              </b-col>
              <b-col md="3" class="text-right">
                <div>
                  <select
                    class="form-select filter-select form-control border"
                    @change="onChangeSort($event)"
                  >
                    <option value="all">Sorting Items</option>
                    <option value="low">price, low to high</option>
                    <option value="high">price, high to low</option>
                  </select>
                </div></b-col
              >
            </div>
          </div>
          <div class="mt-4">
            <div
              class="text-center section-t-space section-b-space"
              v-if="filterProduct.length == 0"
            >
              <b-row>
                <b-col md="5"></b-col>
                <b-col md="7" class="text-center">
                  <b-img inline src="img/search/empty-search.jpg" fluid></b-img>
                  <h3 class="font-weight-bold">
                    Sorry , Couldn't find the result you were looking For!
                  </h3>
                  <p>
                    Please check your spelling or try searching something else.
                  </p>
                  <b-navbar-brand href="/">
                    <b-button variant="primary" class="my-1"
                      >Continue Shopping</b-button
                    ></b-navbar-brand
                  >
                </b-col>
              </b-row>
            </div>
            <div class="row m-0">
              <div class="col-md-4" v-for="(item, i) in filterProduct" :key="i">
                <div
                  class="product-box card shop-box-right"
                  style="border-radius: 3%"
                >
                  <template v-if="$options.components['items-product-card']">
                    <items-product-card :cartItem="item" :key="i" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { fullTextSearch } from "../services/search";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      search: false,
    };
  },
  themeColor: {
    type: Object,
    default: () => ({}),
  },
  computed: {
    ...mapState({
      filterstate: (state) => state.search,
      filterProduct: (state) => state.search.productslist,
      currentPosition: (state) => state.location.currentPosition,
    }),
    numberoFDummyItem() {
      if (this.filterProduct.length > 0) {
        let remainder = this.filterProduct.length % 10;
        if (remainder != 0) {
          return 10 - remainder;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.$route.params.id = to.params.id;
    if (this.$route.params.id.length > 2) {
      if (this.currentPosition) {
        this.fullTextSearchMethod();
      } else {
        this.fullTextSearchMethod();
      }
    } else {
      setTimeout(() => {
        this.$router.push("/");
      }, 1500);
    }
    next();
  },
  methods: {
    allfilter() {
      this.filterData();
    },
    onChangeSort(event) {
      this.$store.dispatch("search/sortProducts", event.target.value);
    },
    async filterData() {
      try {
        const term = this.filterstate.searchText;
        const filters = {
          stringFilters: [],
          numberFilters: [],
        };
        this.filterstate.filters.keywordFilters.forEach((filter) => {
          if (filter.selectedBuckets && filter.selectedBuckets.length > 0) {
            filters.stringFilters.push({
              name: filter.key,
              values: filter.selectedBuckets,
            });
          }
        });
        const dataObj = { term: term, offset: 0, filters: filters };
        this.isLoading = true;
        const result = await this.$store.dispatch(
          "search/getFilterFacet",
          dataObj
        );
        if (result) {
          this.setData(result.hits.hits);
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async facetSearchMethod() {
      if (this.currentPosition.data) {
        const facetSearchParams = {
          term: this.$route.params.id,
          offset: 0,
          filters: {
            stringFilters: [
              {
                name: "Country",
                values: [this.currentPosition.data.Country],
              },
              {
                name: "City",
                values: [this.currentPosition.data.City],
              },
            ],
          },
        };
        this.isLoading = true;
        const result = await this.$store.dispatch(
          "search/getFacetSearch",
          facetSearchParams
        );
        this.isLoading = false;
        if (!result.hits) {
          this.$store.dispatch("search/actionProductList", []);
          this.$store.dispatch("search/setBrandArr", []);
          return;
        }
        this.setData(result.hits.hits);
        let maxPrice = [];
        let brandArr = [];
        const priceRange = {
          max: Math.max(...maxPrice),
          min: Math.min(...maxPrice),
        };
        this.$store.dispatch("search/actionMaxPrice", priceRange);
        this.$store.dispatch("search/setBrandArr", [...new Set(brandArr)]);
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.fullTextSearchMethod();
      }
    },
    fullTextSearchMethod() {
      let searchTerm = this.$route.params.id.trim();
      this.$store.dispatch("search/setSearchText", searchTerm);
      const fullTextParams = {
        term: searchTerm,
        aggregationData: true,
        location: {
          city: "Hyderabad",
        },
      };
      this.isLoading = true;
      fullTextSearch(fullTextParams)
        .then((value) => {
          this.setData(value.hits.hits);
          let maxPrice = [];
          let brandArr = [];
          this.$store.dispatch(
            "search/setKeywordFacet",
            value.aggregations.agg_keyword_facet
          );
          this.$store.dispatch("search/setFilter", value);
          value.hits.hits.filter((ele) => {
            if (ele._source.integer_attributes) {
              for (const i of ele._source.integer_attributes) {
                if (i.attribute_name == "Price")
                  maxPrice.push(i.attribute_value);
              }
            }
            if (ele._source.string_attributes) {
              for (const i of ele._source.string_attributes) {
                if (i.attribute_name == "Brand")
                  brandArr.push(i.attribute_value);
              }
            }
          });
          const priceRange = {
            max: Math.max(...maxPrice),
            min: Math.min(...maxPrice),
          };
          this.$store.dispatch("search/actionMaxPrice", priceRange);
          this.$store.dispatch("search/setBrandArr", [...new Set(brandArr)]);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    setData(result) {
      this.$store.dispatch("search/actionProductList", result);
    },
  },
  async mounted() {
    this.$store.commit("theme/updateTheme", {});
    this.$store.commit("theme/updateName", "");
    if (this.$route.params.id.length > 2) {
      if (this.currentPosition) {
        this.fullTextSearchMethod();
      } else {
        this.fullTextSearchMethod();
      }
    } else {
      setTimeout(() => {
        this.$router.push("/");
      }, 1500);
    }
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.shop-box-right:hover {
  border: 1px solid #272727;
  -webkit-box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.1);
}

select {
  -moz-appearance: auto;
  -webkit-appearance: auto;
}

.text-grey {
  background-color: #f9f9f9;
}

.border {
  border: 3px solid #000;
}

.product-box > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 430px;
  align-items: center;
}

.product-box .img-wrapper .front {
  padding: 15px;
}
</style>
